/* tslint:disable */
/* eslint-disable */
/**
 * Core Tasks API
 * MagMutual Core Tasks API
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { AggregateControlAll } from '../models';
// @ts-ignore
import type { AggregateControlEssential } from '../models';
// @ts-ignore
import type { AggregateControlReviewAll } from '../models';
// @ts-ignore
import type { AggregateControlReviewEssential } from '../models';
// @ts-ignore
import type { Control } from '../models';
// @ts-ignore
import type { ControlIdEssential } from '../models';
// @ts-ignore
import type { ControlReview } from '../models';
// @ts-ignore
import type { ControlReviewDocument } from '../models';
// @ts-ignore
import type { ControlReviewDocumentEssential } from '../models';
// @ts-ignore
import type { ControlReviewDocumentIdEssential } from '../models';
// @ts-ignore
import type { ControlReviewEssential } from '../models';
// @ts-ignore
import type { ControlReviewIdEssential } from '../models';
// @ts-ignore
import type { ControlReviewRevision } from '../models';
// @ts-ignore
import type { ControlReviewRevisionEssential } from '../models';
// @ts-ignore
import type { ControlReviewRevisionIdEssential } from '../models';
// @ts-ignore
import type { ControlReviewStatus } from '../models';
// @ts-ignore
import type { ControlReviewStatusEssential } from '../models';
// @ts-ignore
import type { ControlReviewStatusIdEssential } from '../models';
// @ts-ignore
import type { ControlRevision } from '../models';
// @ts-ignore
import type { ControlRevisionEssential } from '../models';
// @ts-ignore
import type { ControlRevisionIdEssential } from '../models';
// @ts-ignore
import type { ControlStep } from '../models';
// @ts-ignore
import type { ControlStepEssential } from '../models';
// @ts-ignore
import type { ControlStepIdEssential } from '../models';
// @ts-ignore
import type { EmailParams } from '../models';
// @ts-ignore
import type { EmployeeData } from '../models';
// @ts-ignore
import type { Filter } from '../models';
// @ts-ignore
import type { FilterDTO } from '../models';
// @ts-ignore
import type { FilterEssential } from '../models';
// @ts-ignore
import type { FilterIdEssential } from '../models';
// @ts-ignore
import type { FindByColumnQueryUUID } from '../models';
// @ts-ignore
import type { GraphEdge } from '../models';
// @ts-ignore
import type { GraphNode } from '../models';
// @ts-ignore
import type { JobAid } from '../models';
// @ts-ignore
import type { LayoutBodyDTO } from '../models';
// @ts-ignore
import type { Step } from '../models';
// @ts-ignore
import type { StepIdEssential } from '../models';
// @ts-ignore
import type { StepLink } from '../models';
// @ts-ignore
import type { StepLinkEssential } from '../models';
// @ts-ignore
import type { StepLinkIdEssential } from '../models';
// @ts-ignore
import type { StepRevision } from '../models';
// @ts-ignore
import type { StepRevisionEssential } from '../models';
// @ts-ignore
import type { StepRevisionIdEssential } from '../models';
// @ts-ignore
import type { User } from '../models';
// @ts-ignore
import type { UserEssential } from '../models';
// @ts-ignore
import type { UserFilter } from '../models';
// @ts-ignore
import type { Zone } from '../models';
// @ts-ignore
import type { ZoneAggregate } from '../models';
// @ts-ignore
import type { ZoneIdEssential } from '../models';
// @ts-ignore
import type { ZoneLevel } from '../models';
// @ts-ignore
import type { ZoneLevelEssential } from '../models';
// @ts-ignore
import type { ZoneLevelIdEssential } from '../models';
// @ts-ignore
import type { ZoneOwner } from '../models';
// @ts-ignore
import type { ZoneOwnerEssential } from '../models';
// @ts-ignore
import type { ZoneOwnerIdEssential } from '../models';
/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AggregateControlEssential} aggregateControlEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlControllerCreateAggregate: async (aggregateControlEssential: AggregateControlEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aggregateControlEssential' is not null or undefined
            assertParamExists('aggregateControlControllerCreateAggregate', 'aggregateControlEssential', aggregateControlEssential)
            const localVarPath = `/aggregate/control/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aggregateControlEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlControllerGetAggregate: async (id: string, instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('aggregateControlControllerGetAggregate', 'id', id)
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('aggregateControlControllerGetAggregate', 'instant', instant)
            const localVarPath = `/aggregate/control/get/{id}/{instant}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlControllerGetAllAggregate: async (instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('aggregateControlControllerGetAllAggregate', 'instant', instant)
            const localVarPath = `/aggregate/control/get/all/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} step 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlControllerGetAllAggregateByStep: async (step: string, instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'step' is not null or undefined
            assertParamExists('aggregateControlControllerGetAllAggregateByStep', 'step', step)
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('aggregateControlControllerGetAllAggregateByStep', 'instant', instant)
            const localVarPath = `/aggregate/control/get-by-step/{step}/{instant}`
                .replace(`{${"step"}}`, encodeURIComponent(String(step)))
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlControllerRemoveAggregate: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('aggregateControlControllerRemoveAggregate', 'id', id)
            const localVarPath = `/aggregate/control/remove/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AggregateControlAll} aggregateControlAll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlControllerUpdateAggregate: async (aggregateControlAll: AggregateControlAll, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aggregateControlAll' is not null or undefined
            assertParamExists('aggregateControlControllerUpdateAggregate', 'aggregateControlAll', aggregateControlAll)
            const localVarPath = `/aggregate/control/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aggregateControlAll, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AggregateControlReviewEssential} aggregateControlReviewEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlReviewControllerCreateAggregate: async (aggregateControlReviewEssential: AggregateControlReviewEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aggregateControlReviewEssential' is not null or undefined
            assertParamExists('aggregateControlReviewControllerCreateAggregate', 'aggregateControlReviewEssential', aggregateControlReviewEssential)
            const localVarPath = `/aggregate/control-review/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aggregateControlReviewEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlReviewControllerGetAggregate: async (id: string, instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('aggregateControlReviewControllerGetAggregate', 'id', id)
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('aggregateControlReviewControllerGetAggregate', 'instant', instant)
            const localVarPath = `/aggregate/control-review/get/{id}/{instant}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlReviewControllerGetAllAggregate: async (instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('aggregateControlReviewControllerGetAllAggregate', 'instant', instant)
            const localVarPath = `/aggregate/control-review/get/all/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlReviewControllerRemoveAggregate: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('aggregateControlReviewControllerRemoveAggregate', 'id', id)
            const localVarPath = `/aggregate/control-review/remove/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AggregateControlReviewAll} aggregateControlReviewAll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlReviewControllerUpdateAggregate: async (aggregateControlReviewAll: AggregateControlReviewAll, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aggregateControlReviewAll' is not null or undefined
            assertParamExists('aggregateControlReviewControllerUpdateAggregate', 'aggregateControlReviewAll', aggregateControlReviewAll)
            const localVarPath = `/aggregate/control-review/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aggregateControlReviewAll, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationControllerGetPossibleDelegates: async (email: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('authenticationControllerGetPossibleDelegates', 'email', email)
            const localVarPath = `/authentication/possible-delegates/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} processUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationControllerGetResponsibleUsers: async (processUuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processUuid' is not null or undefined
            assertParamExists('authenticationControllerGetResponsibleUsers', 'processUuid', processUuid)
            const localVarPath = `/authentication/responsible-users/{processUuid}`
                .replace(`{${"processUuid"}}`, encodeURIComponent(String(processUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlControllerCreateEntity: async (body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('controlControllerCreateEntity', 'body', body)
            const localVarPath = `/control/control/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlControllerDeleteEntity', 'id', id)
            const localVarPath = `/control/control/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/control/control`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlControllerGetById', 'id', id)
            const localVarPath = `/control/control/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlIdEssential} controlIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlControllerUpdateEntity: async (controlIdEssential: ControlIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlIdEssential' is not null or undefined
            assertParamExists('controlControllerUpdateEntity', 'controlIdEssential', controlIdEssential)
            const localVarPath = `/control/control/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlReviewEssential} controlReviewEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewControllerCreateEntity: async (controlReviewEssential: ControlReviewEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlReviewEssential' is not null or undefined
            assertParamExists('controlReviewControllerCreateEntity', 'controlReviewEssential', controlReviewEssential)
            const localVarPath = `/control/control-review/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlReviewEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlReviewControllerDeleteEntity', 'id', id)
            const localVarPath = `/control/control-review/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/control/control-review`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlReviewControllerGetById', 'id', id)
            const localVarPath = `/control/control-review/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlReviewIdEssential} controlReviewIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewControllerUpdateEntity: async (controlReviewIdEssential: ControlReviewIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlReviewIdEssential' is not null or undefined
            assertParamExists('controlReviewControllerUpdateEntity', 'controlReviewIdEssential', controlReviewIdEssential)
            const localVarPath = `/control/control-review/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlReviewIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlReviewDocumentEssential} controlReviewDocumentEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewDocumentControllerCreateEntity: async (controlReviewDocumentEssential: ControlReviewDocumentEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlReviewDocumentEssential' is not null or undefined
            assertParamExists('controlReviewDocumentControllerCreateEntity', 'controlReviewDocumentEssential', controlReviewDocumentEssential)
            const localVarPath = `/control/control-review-document/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlReviewDocumentEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewDocumentControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlReviewDocumentControllerDeleteEntity', 'id', id)
            const localVarPath = `/control/control-review-document/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewDocumentControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/control/control-review-document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewDocumentControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlReviewDocumentControllerGetById', 'id', id)
            const localVarPath = `/control/control-review-document/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlReviewDocumentIdEssential} controlReviewDocumentIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewDocumentControllerUpdateEntity: async (controlReviewDocumentIdEssential: ControlReviewDocumentIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlReviewDocumentIdEssential' is not null or undefined
            assertParamExists('controlReviewDocumentControllerUpdateEntity', 'controlReviewDocumentIdEssential', controlReviewDocumentIdEssential)
            const localVarPath = `/control/control-review-document/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlReviewDocumentIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlReviewRevisionEssential} controlReviewRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewRevisionControllerCreateEntity: async (controlReviewRevisionEssential: ControlReviewRevisionEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlReviewRevisionEssential' is not null or undefined
            assertParamExists('controlReviewRevisionControllerCreateEntity', 'controlReviewRevisionEssential', controlReviewRevisionEssential)
            const localVarPath = `/control/control-review-revision/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlReviewRevisionEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewRevisionControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlReviewRevisionControllerDeleteEntity', 'id', id)
            const localVarPath = `/control/control-review-revision/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewRevisionControllerFindEntity: async (findByColumnQueryUUID: FindByColumnQueryUUID, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'findByColumnQueryUUID' is not null or undefined
            assertParamExists('controlReviewRevisionControllerFindEntity', 'findByColumnQueryUUID', findByColumnQueryUUID)
            const localVarPath = `/control/control-review-revision/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findByColumnQueryUUID, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewRevisionControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/control/control-review-revision`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewRevisionControllerGetAllEntities: async (instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('controlReviewRevisionControllerGetAllEntities', 'instant', instant)
            const localVarPath = `/control/control-review-revision/get/all/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewRevisionControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlReviewRevisionControllerGetById', 'id', id)
            const localVarPath = `/control/control-review-revision/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewRevisionControllerRemoveEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlReviewRevisionControllerRemoveEntity', 'id', id)
            const localVarPath = `/control/control-review-revision/remove/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlReviewRevisionIdEssential} controlReviewRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewRevisionControllerUpdateEntity: async (controlReviewRevisionIdEssential: ControlReviewRevisionIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlReviewRevisionIdEssential' is not null or undefined
            assertParamExists('controlReviewRevisionControllerUpdateEntity', 'controlReviewRevisionIdEssential', controlReviewRevisionIdEssential)
            const localVarPath = `/control/control-review-revision/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlReviewRevisionIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlReviewStatusEssential} controlReviewStatusEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewStatusControllerCreateEntity: async (controlReviewStatusEssential: ControlReviewStatusEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlReviewStatusEssential' is not null or undefined
            assertParamExists('controlReviewStatusControllerCreateEntity', 'controlReviewStatusEssential', controlReviewStatusEssential)
            const localVarPath = `/control/control-review-status/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlReviewStatusEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewStatusControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlReviewStatusControllerDeleteEntity', 'id', id)
            const localVarPath = `/control/control-review-status/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewStatusControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/control/control-review-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewStatusControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlReviewStatusControllerGetById', 'id', id)
            const localVarPath = `/control/control-review-status/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlReviewStatusIdEssential} controlReviewStatusIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewStatusControllerUpdateEntity: async (controlReviewStatusIdEssential: ControlReviewStatusIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlReviewStatusIdEssential' is not null or undefined
            assertParamExists('controlReviewStatusControllerUpdateEntity', 'controlReviewStatusIdEssential', controlReviewStatusIdEssential)
            const localVarPath = `/control/control-review-status/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlReviewStatusIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlRevisionEssential} controlRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerCreateEntity: async (controlRevisionEssential: ControlRevisionEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlRevisionEssential' is not null or undefined
            assertParamExists('controlRevisionControllerCreateEntity', 'controlRevisionEssential', controlRevisionEssential)
            const localVarPath = `/control/control-revision/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlRevisionEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlRevisionControllerDeleteEntity', 'id', id)
            const localVarPath = `/control/control-revision/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerFindEntity: async (findByColumnQueryUUID: FindByColumnQueryUUID, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'findByColumnQueryUUID' is not null or undefined
            assertParamExists('controlRevisionControllerFindEntity', 'findByColumnQueryUUID', findByColumnQueryUUID)
            const localVarPath = `/control/control-revision/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findByColumnQueryUUID, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/control/control-revision`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerGetAllEntities: async (instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('controlRevisionControllerGetAllEntities', 'instant', instant)
            const localVarPath = `/control/control-revision/get/all/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlRevisionControllerGetById', 'id', id)
            const localVarPath = `/control/control-revision/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerRemoveEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlRevisionControllerRemoveEntity', 'id', id)
            const localVarPath = `/control/control-revision/remove/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlRevisionIdEssential} controlRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerUpdateEntity: async (controlRevisionIdEssential: ControlRevisionIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlRevisionIdEssential' is not null or undefined
            assertParamExists('controlRevisionControllerUpdateEntity', 'controlRevisionIdEssential', controlRevisionIdEssential)
            const localVarPath = `/control/control-revision/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlRevisionIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlStepEssential} controlStepEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerCreateEntity: async (controlStepEssential: ControlStepEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlStepEssential' is not null or undefined
            assertParamExists('controlStepControllerCreateEntity', 'controlStepEssential', controlStepEssential)
            const localVarPath = `/control/control-step/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlStepEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlStepControllerDeleteEntity', 'id', id)
            const localVarPath = `/control/control-step/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerFindEntity: async (findByColumnQueryUUID: FindByColumnQueryUUID, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'findByColumnQueryUUID' is not null or undefined
            assertParamExists('controlStepControllerFindEntity', 'findByColumnQueryUUID', findByColumnQueryUUID)
            const localVarPath = `/control/control-step/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findByColumnQueryUUID, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/control/control-step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerGetAllEntities: async (instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('controlStepControllerGetAllEntities', 'instant', instant)
            const localVarPath = `/control/control-step/get/all/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlStepControllerGetById', 'id', id)
            const localVarPath = `/control/control-step/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerRemoveEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlStepControllerRemoveEntity', 'id', id)
            const localVarPath = `/control/control-step/remove/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlStepIdEssential} controlStepIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerUpdateEntity: async (controlStepIdEssential: ControlStepIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlStepIdEssential' is not null or undefined
            assertParamExists('controlStepControllerUpdateEntity', 'controlStepIdEssential', controlStepIdEssential)
            const localVarPath = `/control/control-step/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlStepIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} zone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreTasksApiControllerImportAndSaveSteps: async (zone: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zone' is not null or undefined
            assertParamExists('coreTasksApiControllerImportAndSaveSteps', 'zone', zone)
            const localVarPath = `/import-steps/{zone}`
                .replace(`{${"zone"}}`, encodeURIComponent(String(zone)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreTasksApiControllerPing: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        displayDataControllerGetControls: async (instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('displayDataControllerGetControls', 'instant', instant)
            const localVarPath = `/display/controls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (instant !== undefined) {
                localVarQueryParameter['instant'] = (instant as any instanceof Date) ?
                    (instant as any).toISOString() :
                    instant;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} to 
         * @param {string} subject 
         * @param {EmailParams} emailParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailControllerSendEmail: async (to: Array<string>, subject: string, emailParams: EmailParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'to' is not null or undefined
            assertParamExists('emailControllerSendEmail', 'to', to)
            // verify required parameter 'subject' is not null or undefined
            assertParamExists('emailControllerSendEmail', 'subject', subject)
            // verify required parameter 'emailParams' is not null or undefined
            assertParamExists('emailControllerSendEmail', 'emailParams', emailParams)
            const localVarPath = `/notification/email/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (to) {
                localVarQueryParameter['to'] = to;
            }

            if (subject !== undefined) {
                localVarQueryParameter['subject'] = subject;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDataControllerGetAllEmployeeData: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/workday/employee/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FilterEssential} filterEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterControllerCreateEntity: async (filterEssential: FilterEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filterEssential' is not null or undefined
            assertParamExists('filterControllerCreateEntity', 'filterEssential', filterEssential)
            const localVarPath = `/preference/filters/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filterEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('filterControllerDeleteEntity', 'id', id)
            const localVarPath = `/preference/filters/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/preference/filters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('filterControllerGetById', 'id', id)
            const localVarPath = `/preference/filters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} filterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterControllerGetUserByFilter: async (filterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filterId' is not null or undefined
            assertParamExists('filterControllerGetUserByFilter', 'filterId', filterId)
            const localVarPath = `/preference/filters/{filterId}/users`
                .replace(`{${"filterId"}}`, encodeURIComponent(String(filterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FilterIdEssential} filterIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterControllerUpdateEntity: async (filterIdEssential: FilterIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filterIdEssential' is not null or undefined
            assertParamExists('filterControllerUpdateEntity', 'filterIdEssential', filterIdEssential)
            const localVarPath = `/preference/filters/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filterIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {LayoutBodyDTO} layoutBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphControllerGetNestedLayout: async (instant: string, layoutBodyDTO: LayoutBodyDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('graphControllerGetNestedLayout', 'instant', instant)
            // verify required parameter 'layoutBodyDTO' is not null or undefined
            assertParamExists('graphControllerGetNestedLayout', 'layoutBodyDTO', layoutBodyDTO)
            const localVarPath = `/graph/layout/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(layoutBodyDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {LayoutBodyDTO} layoutBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphControllerGetRenderedEdges: async (instant: string, layoutBodyDTO: LayoutBodyDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('graphControllerGetRenderedEdges', 'instant', instant)
            // verify required parameter 'layoutBodyDTO' is not null or undefined
            assertParamExists('graphControllerGetRenderedEdges', 'layoutBodyDTO', layoutBodyDTO)
            const localVarPath = `/graph/edges/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(layoutBodyDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobAidControllerGetFromUrl: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/workday/jobaid/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerCreateEntity: async (body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('stepControllerCreateEntity', 'body', body)
            const localVarPath = `/process/step/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepControllerDeleteEntity', 'id', id)
            const localVarPath = `/process/step/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/process/step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepControllerGetById', 'id', id)
            const localVarPath = `/process/step/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StepIdEssential} stepIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerUpdateEntity: async (stepIdEssential: StepIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepIdEssential' is not null or undefined
            assertParamExists('stepControllerUpdateEntity', 'stepIdEssential', stepIdEssential)
            const localVarPath = `/process/step/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StepLinkEssential} stepLinkEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerCreateEntity: async (stepLinkEssential: StepLinkEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepLinkEssential' is not null or undefined
            assertParamExists('stepLinkControllerCreateEntity', 'stepLinkEssential', stepLinkEssential)
            const localVarPath = `/process/step-link/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepLinkEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepLinkControllerDeleteEntity', 'id', id)
            const localVarPath = `/process/step-link/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerFindEntity: async (findByColumnQueryUUID: FindByColumnQueryUUID, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'findByColumnQueryUUID' is not null or undefined
            assertParamExists('stepLinkControllerFindEntity', 'findByColumnQueryUUID', findByColumnQueryUUID)
            const localVarPath = `/process/step-link/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findByColumnQueryUUID, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/process/step-link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerGetAllEntities: async (instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('stepLinkControllerGetAllEntities', 'instant', instant)
            const localVarPath = `/process/step-link/get/all/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepLinkControllerGetById', 'id', id)
            const localVarPath = `/process/step-link/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerRemoveEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepLinkControllerRemoveEntity', 'id', id)
            const localVarPath = `/process/step-link/remove/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StepLinkIdEssential} stepLinkIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerUpdateEntity: async (stepLinkIdEssential: StepLinkIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepLinkIdEssential' is not null or undefined
            assertParamExists('stepLinkControllerUpdateEntity', 'stepLinkIdEssential', stepLinkIdEssential)
            const localVarPath = `/process/step-link/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepLinkIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<StepRevision>} stepRevision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerCreateBulkEntityForce: async (stepRevision: Array<StepRevision>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepRevision' is not null or undefined
            assertParamExists('stepRevisionControllerCreateBulkEntityForce', 'stepRevision', stepRevision)
            const localVarPath = `/process/step-revision/create/bulk/force`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepRevision, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StepRevisionEssential} stepRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerCreateEntity: async (stepRevisionEssential: StepRevisionEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepRevisionEssential' is not null or undefined
            assertParamExists('stepRevisionControllerCreateEntity', 'stepRevisionEssential', stepRevisionEssential)
            const localVarPath = `/process/step-revision/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepRevisionEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StepRevision} stepRevision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerCreateEntityForce: async (stepRevision: StepRevision, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepRevision' is not null or undefined
            assertParamExists('stepRevisionControllerCreateEntityForce', 'stepRevision', stepRevision)
            const localVarPath = `/process/step-revision/create/force`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepRevision, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepRevisionControllerDeleteEntity', 'id', id)
            const localVarPath = `/process/step-revision/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fieldName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerFindAllDistinct: async (fieldName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldName' is not null or undefined
            assertParamExists('stepRevisionControllerFindAllDistinct', 'fieldName', fieldName)
            const localVarPath = `/process/step-revision/distinct/{fieldName}`
                .replace(`{${"fieldName"}}`, encodeURIComponent(String(fieldName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fieldName 
         * @param {FilterDTO} filterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerFindAllDistinctGivenFilter: async (fieldName: string, filterDTO: FilterDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldName' is not null or undefined
            assertParamExists('stepRevisionControllerFindAllDistinctGivenFilter', 'fieldName', fieldName)
            // verify required parameter 'filterDTO' is not null or undefined
            assertParamExists('stepRevisionControllerFindAllDistinctGivenFilter', 'filterDTO', filterDTO)
            const localVarPath = `/process/step-revision/distinct-filter/{fieldName}`
                .replace(`{${"fieldName"}}`, encodeURIComponent(String(fieldName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filterDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {FilterDTO} filterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerFindAllGivenFilter: async (instant: string, filterDTO: FilterDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('stepRevisionControllerFindAllGivenFilter', 'instant', instant)
            // verify required parameter 'filterDTO' is not null or undefined
            assertParamExists('stepRevisionControllerFindAllGivenFilter', 'filterDTO', filterDTO)
            const localVarPath = `/process/step-revision/filter/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filterDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerFindEntity: async (findByColumnQueryUUID: FindByColumnQueryUUID, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'findByColumnQueryUUID' is not null or undefined
            assertParamExists('stepRevisionControllerFindEntity', 'findByColumnQueryUUID', findByColumnQueryUUID)
            const localVarPath = `/process/step-revision/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findByColumnQueryUUID, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/process/step-revision`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerGetAllEntities: async (instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('stepRevisionControllerGetAllEntities', 'instant', instant)
            const localVarPath = `/process/step-revision/get/all/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepRevisionControllerGetById', 'id', id)
            const localVarPath = `/process/step-revision/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerRemoveEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepRevisionControllerRemoveEntity', 'id', id)
            const localVarPath = `/process/step-revision/remove/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StepRevisionIdEssential} stepRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerUpdateEntity: async (stepRevisionIdEssential: StepRevisionIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepRevisionIdEssential' is not null or undefined
            assertParamExists('stepRevisionControllerUpdateEntity', 'stepRevisionIdEssential', stepRevisionIdEssential)
            const localVarPath = `/process/step-revision/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepRevisionIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StepRevision} stepRevision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerUpdateEntityForce: async (stepRevision: StepRevision, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepRevision' is not null or undefined
            assertParamExists('stepRevisionControllerUpdateEntityForce', 'stepRevision', stepRevision)
            const localVarPath = `/process/step-revision/update/force`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepRevision, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} filterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerAssignFilter: async (userId: string, filterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userControllerAssignFilter', 'userId', userId)
            // verify required parameter 'filterId' is not null or undefined
            assertParamExists('userControllerAssignFilter', 'filterId', filterId)
            const localVarPath = `/preference/users/{userId}/assign-filter`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterId !== undefined) {
                localVarQueryParameter['filterId'] = filterId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserEssential} userEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreateEntity: async (userEssential: UserEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userEssential' is not null or undefined
            assertParamExists('userControllerCreateEntity', 'userEssential', userEssential)
            const localVarPath = `/preference/users/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userControllerDeleteEntity', 'id', id)
            const localVarPath = `/preference/users/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/preference/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userControllerGetById', 'id', id)
            const localVarPath = `/preference/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetFiltersByUser: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userControllerGetFiltersByUser', 'userId', userId)
            const localVarPath = `/preference/users/{userId}/filters`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} filterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUnassignFilter: async (userId: string, filterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userControllerUnassignFilter', 'userId', userId)
            // verify required parameter 'filterId' is not null or undefined
            assertParamExists('userControllerUnassignFilter', 'filterId', filterId)
            const localVarPath = `/preference/users/{userId}/unassign-filter`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterId !== undefined) {
                localVarQueryParameter['filterId'] = filterId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerCreateEntity: async (body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('zoneControllerCreateEntity', 'body', body)
            const localVarPath = `/zone/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('zoneControllerDeleteEntity', 'id', id)
            const localVarPath = `/zone/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/zone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerGetAllPermissionsByUserId: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('zoneControllerGetAllPermissionsByUserId', 'id', id)
            const localVarPath = `/zone/permissions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('zoneControllerGetById', 'id', id)
            const localVarPath = `/zone/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ZoneIdEssential} zoneIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerUpdateEntity: async (zoneIdEssential: ZoneIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zoneIdEssential' is not null or undefined
            assertParamExists('zoneControllerUpdateEntity', 'zoneIdEssential', zoneIdEssential)
            const localVarPath = `/zone/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zoneIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ZoneLevelEssential} zoneLevelEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneLevelControllerCreateEntity: async (zoneLevelEssential: ZoneLevelEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zoneLevelEssential' is not null or undefined
            assertParamExists('zoneLevelControllerCreateEntity', 'zoneLevelEssential', zoneLevelEssential)
            const localVarPath = `/zone/level/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zoneLevelEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneLevelControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('zoneLevelControllerDeleteEntity', 'id', id)
            const localVarPath = `/zone/level/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneLevelControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/zone/level`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneLevelControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('zoneLevelControllerGetById', 'id', id)
            const localVarPath = `/zone/level/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ZoneLevelIdEssential} zoneLevelIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneLevelControllerUpdateEntity: async (zoneLevelIdEssential: ZoneLevelIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zoneLevelIdEssential' is not null or undefined
            assertParamExists('zoneLevelControllerUpdateEntity', 'zoneLevelIdEssential', zoneLevelIdEssential)
            const localVarPath = `/zone/level/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zoneLevelIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ZoneOwnerEssential} zoneOwnerEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneOwnerControllerCreateEntity: async (zoneOwnerEssential: ZoneOwnerEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zoneOwnerEssential' is not null or undefined
            assertParamExists('zoneOwnerControllerCreateEntity', 'zoneOwnerEssential', zoneOwnerEssential)
            const localVarPath = `/zone/owner/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zoneOwnerEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneOwnerControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('zoneOwnerControllerDeleteEntity', 'id', id)
            const localVarPath = `/zone/owner/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneOwnerControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/zone/owner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneOwnerControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('zoneOwnerControllerGetById', 'id', id)
            const localVarPath = `/zone/owner/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ZoneOwnerIdEssential} zoneOwnerIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneOwnerControllerUpdateEntity: async (zoneOwnerIdEssential: ZoneOwnerIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zoneOwnerIdEssential' is not null or undefined
            assertParamExists('zoneOwnerControllerUpdateEntity', 'zoneOwnerIdEssential', zoneOwnerIdEssential)
            const localVarPath = `/zone/owner/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zoneOwnerIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AggregateControlEssential} aggregateControlEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateControlControllerCreateAggregate(aggregateControlEssential: AggregateControlEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateControlAll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateControlControllerCreateAggregate(aggregateControlEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.aggregateControlControllerCreateAggregate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateControlControllerGetAggregate(id: string, instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateControlAll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateControlControllerGetAggregate(id, instant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.aggregateControlControllerGetAggregate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateControlControllerGetAllAggregate(instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AggregateControlAll>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateControlControllerGetAllAggregate(instant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.aggregateControlControllerGetAllAggregate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} step 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateControlControllerGetAllAggregateByStep(step: string, instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AggregateControlAll>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateControlControllerGetAllAggregateByStep(step, instant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.aggregateControlControllerGetAllAggregateByStep']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateControlControllerRemoveAggregate(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateControlAll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateControlControllerRemoveAggregate(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.aggregateControlControllerRemoveAggregate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AggregateControlAll} aggregateControlAll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateControlControllerUpdateAggregate(aggregateControlAll: AggregateControlAll, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateControlAll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateControlControllerUpdateAggregate(aggregateControlAll, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.aggregateControlControllerUpdateAggregate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AggregateControlReviewEssential} aggregateControlReviewEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateControlReviewControllerCreateAggregate(aggregateControlReviewEssential: AggregateControlReviewEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateControlReviewAll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateControlReviewControllerCreateAggregate(aggregateControlReviewEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.aggregateControlReviewControllerCreateAggregate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateControlReviewControllerGetAggregate(id: string, instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateControlReviewAll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateControlReviewControllerGetAggregate(id, instant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.aggregateControlReviewControllerGetAggregate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateControlReviewControllerGetAllAggregate(instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AggregateControlReviewAll>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateControlReviewControllerGetAllAggregate(instant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.aggregateControlReviewControllerGetAllAggregate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateControlReviewControllerRemoveAggregate(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateControlReviewAll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateControlReviewControllerRemoveAggregate(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.aggregateControlReviewControllerRemoveAggregate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AggregateControlReviewAll} aggregateControlReviewAll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateControlReviewControllerUpdateAggregate(aggregateControlReviewAll: AggregateControlReviewAll, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateControlReviewAll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateControlReviewControllerUpdateAggregate(aggregateControlReviewAll, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.aggregateControlReviewControllerUpdateAggregate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationControllerGetPossibleDelegates(email: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationControllerGetPossibleDelegates(email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.authenticationControllerGetPossibleDelegates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} processUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationControllerGetResponsibleUsers(processUuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationControllerGetResponsibleUsers(processUuid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.authenticationControllerGetResponsibleUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlControllerCreateEntity(body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Control>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlControllerCreateEntity(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlControllerCreateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlControllerDeleteEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlControllerDeleteEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Control>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlControllerGetAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlControllerGetAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Control>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlControllerGetById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlControllerGetById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ControlIdEssential} controlIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlControllerUpdateEntity(controlIdEssential: ControlIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Control>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlControllerUpdateEntity(controlIdEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlControllerUpdateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ControlReviewEssential} controlReviewEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewControllerCreateEntity(controlReviewEssential: ControlReviewEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlReview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewControllerCreateEntity(controlReviewEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlReviewControllerCreateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewControllerDeleteEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlReviewControllerDeleteEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlReview>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewControllerGetAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlReviewControllerGetAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlReview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewControllerGetById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlReviewControllerGetById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ControlReviewIdEssential} controlReviewIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewControllerUpdateEntity(controlReviewIdEssential: ControlReviewIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlReview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewControllerUpdateEntity(controlReviewIdEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlReviewControllerUpdateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ControlReviewDocumentEssential} controlReviewDocumentEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewDocumentControllerCreateEntity(controlReviewDocumentEssential: ControlReviewDocumentEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlReviewDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewDocumentControllerCreateEntity(controlReviewDocumentEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlReviewDocumentControllerCreateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewDocumentControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewDocumentControllerDeleteEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlReviewDocumentControllerDeleteEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewDocumentControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlReviewDocument>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewDocumentControllerGetAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlReviewDocumentControllerGetAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewDocumentControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlReviewDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewDocumentControllerGetById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlReviewDocumentControllerGetById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ControlReviewDocumentIdEssential} controlReviewDocumentIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewDocumentControllerUpdateEntity(controlReviewDocumentIdEssential: ControlReviewDocumentIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlReviewDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewDocumentControllerUpdateEntity(controlReviewDocumentIdEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlReviewDocumentControllerUpdateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ControlReviewRevisionEssential} controlReviewRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewRevisionControllerCreateEntity(controlReviewRevisionEssential: ControlReviewRevisionEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlReviewRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewRevisionControllerCreateEntity(controlReviewRevisionEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlReviewRevisionControllerCreateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewRevisionControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewRevisionControllerDeleteEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlReviewRevisionControllerDeleteEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewRevisionControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlReviewRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewRevisionControllerFindEntity(findByColumnQueryUUID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlReviewRevisionControllerFindEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewRevisionControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlReviewRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewRevisionControllerGetAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlReviewRevisionControllerGetAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewRevisionControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlReviewRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewRevisionControllerGetAllEntities(instant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlReviewRevisionControllerGetAllEntities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewRevisionControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlReviewRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewRevisionControllerGetById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlReviewRevisionControllerGetById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewRevisionControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlReviewRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewRevisionControllerRemoveEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlReviewRevisionControllerRemoveEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ControlReviewRevisionIdEssential} controlReviewRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewRevisionControllerUpdateEntity(controlReviewRevisionIdEssential: ControlReviewRevisionIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlReviewRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewRevisionControllerUpdateEntity(controlReviewRevisionIdEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlReviewRevisionControllerUpdateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ControlReviewStatusEssential} controlReviewStatusEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewStatusControllerCreateEntity(controlReviewStatusEssential: ControlReviewStatusEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlReviewStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewStatusControllerCreateEntity(controlReviewStatusEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlReviewStatusControllerCreateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewStatusControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewStatusControllerDeleteEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlReviewStatusControllerDeleteEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewStatusControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlReviewStatus>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewStatusControllerGetAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlReviewStatusControllerGetAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewStatusControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlReviewStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewStatusControllerGetById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlReviewStatusControllerGetById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ControlReviewStatusIdEssential} controlReviewStatusIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewStatusControllerUpdateEntity(controlReviewStatusIdEssential: ControlReviewStatusIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlReviewStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewStatusControllerUpdateEntity(controlReviewStatusIdEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlReviewStatusControllerUpdateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ControlRevisionEssential} controlRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlRevisionControllerCreateEntity(controlRevisionEssential: ControlRevisionEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlRevisionControllerCreateEntity(controlRevisionEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlRevisionControllerCreateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlRevisionControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlRevisionControllerDeleteEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlRevisionControllerDeleteEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlRevisionControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlRevisionControllerFindEntity(findByColumnQueryUUID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlRevisionControllerFindEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlRevisionControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlRevisionControllerGetAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlRevisionControllerGetAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlRevisionControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlRevisionControllerGetAllEntities(instant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlRevisionControllerGetAllEntities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlRevisionControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlRevisionControllerGetById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlRevisionControllerGetById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlRevisionControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlRevisionControllerRemoveEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlRevisionControllerRemoveEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ControlRevisionIdEssential} controlRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlRevisionControllerUpdateEntity(controlRevisionIdEssential: ControlRevisionIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlRevisionControllerUpdateEntity(controlRevisionIdEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlRevisionControllerUpdateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ControlStepEssential} controlStepEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlStepControllerCreateEntity(controlStepEssential: ControlStepEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlStepControllerCreateEntity(controlStepEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlStepControllerCreateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlStepControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlStepControllerDeleteEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlStepControllerDeleteEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlStepControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlStep>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlStepControllerFindEntity(findByColumnQueryUUID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlStepControllerFindEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlStepControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlStep>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlStepControllerGetAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlStepControllerGetAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlStepControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlStep>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlStepControllerGetAllEntities(instant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlStepControllerGetAllEntities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlStepControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlStepControllerGetById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlStepControllerGetById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlStepControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlStepControllerRemoveEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlStepControllerRemoveEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ControlStepIdEssential} controlStepIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlStepControllerUpdateEntity(controlStepIdEssential: ControlStepIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlStepControllerUpdateEntity(controlStepIdEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.controlStepControllerUpdateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} zone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreTasksApiControllerImportAndSaveSteps(zone: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Step>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreTasksApiControllerImportAndSaveSteps(zone, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.coreTasksApiControllerImportAndSaveSteps']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreTasksApiControllerPing(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreTasksApiControllerPing(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.coreTasksApiControllerPing']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async displayDataControllerGetControls(instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.displayDataControllerGetControls(instant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.displayDataControllerGetControls']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} to 
         * @param {string} subject 
         * @param {EmailParams} emailParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailControllerSendEmail(to: Array<string>, subject: string, emailParams: EmailParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailControllerSendEmail(to, subject, emailParams, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.emailControllerSendEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeDataControllerGetAllEmployeeData(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeDataControllerGetAllEmployeeData(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.employeeDataControllerGetAllEmployeeData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FilterEssential} filterEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filterControllerCreateEntity(filterEssential: FilterEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Filter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filterControllerCreateEntity(filterEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.filterControllerCreateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filterControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filterControllerDeleteEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.filterControllerDeleteEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filterControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Filter>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filterControllerGetAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.filterControllerGetAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filterControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Filter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filterControllerGetById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.filterControllerGetById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} filterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filterControllerGetUserByFilter(filterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filterControllerGetUserByFilter(filterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.filterControllerGetUserByFilter']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FilterIdEssential} filterIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filterControllerUpdateEntity(filterIdEssential: FilterIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Filter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filterControllerUpdateEntity(filterIdEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.filterControllerUpdateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {LayoutBodyDTO} layoutBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async graphControllerGetNestedLayout(instant: string, layoutBodyDTO: LayoutBodyDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GraphNode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.graphControllerGetNestedLayout(instant, layoutBodyDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.graphControllerGetNestedLayout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {LayoutBodyDTO} layoutBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async graphControllerGetRenderedEdges(instant: string, layoutBodyDTO: LayoutBodyDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GraphEdge>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.graphControllerGetRenderedEdges(instant, layoutBodyDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.graphControllerGetRenderedEdges']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jobAidControllerGetFromUrl(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JobAid>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jobAidControllerGetFromUrl(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.jobAidControllerGetFromUrl']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepControllerCreateEntity(body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Step>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepControllerCreateEntity(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepControllerCreateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepControllerDeleteEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepControllerDeleteEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Step>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepControllerGetAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepControllerGetAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Step>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepControllerGetById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepControllerGetById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {StepIdEssential} stepIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepControllerUpdateEntity(stepIdEssential: StepIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Step>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepControllerUpdateEntity(stepIdEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepControllerUpdateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {StepLinkEssential} stepLinkEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepLinkControllerCreateEntity(stepLinkEssential: StepLinkEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepLinkControllerCreateEntity(stepLinkEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepLinkControllerCreateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepLinkControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepLinkControllerDeleteEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepLinkControllerDeleteEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepLinkControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepLink>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepLinkControllerFindEntity(findByColumnQueryUUID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepLinkControllerFindEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepLinkControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepLink>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepLinkControllerGetAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepLinkControllerGetAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepLinkControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepLink>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepLinkControllerGetAllEntities(instant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepLinkControllerGetAllEntities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepLinkControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepLinkControllerGetById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepLinkControllerGetById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepLinkControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepLinkControllerRemoveEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepLinkControllerRemoveEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {StepLinkIdEssential} stepLinkIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepLinkControllerUpdateEntity(stepLinkIdEssential: StepLinkIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepLinkControllerUpdateEntity(stepLinkIdEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepLinkControllerUpdateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<StepRevision>} stepRevision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerCreateBulkEntityForce(stepRevision: Array<StepRevision>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerCreateBulkEntityForce(stepRevision, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerCreateBulkEntityForce']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {StepRevisionEssential} stepRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerCreateEntity(stepRevisionEssential: StepRevisionEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerCreateEntity(stepRevisionEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerCreateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {StepRevision} stepRevision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerCreateEntityForce(stepRevision: StepRevision, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerCreateEntityForce(stepRevision, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerCreateEntityForce']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerDeleteEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerDeleteEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} fieldName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerFindAllDistinct(fieldName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerFindAllDistinct(fieldName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerFindAllDistinct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} fieldName 
         * @param {FilterDTO} filterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerFindAllDistinctGivenFilter(fieldName: string, filterDTO: FilterDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerFindAllDistinctGivenFilter(fieldName, filterDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerFindAllDistinctGivenFilter']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {FilterDTO} filterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerFindAllGivenFilter(instant: string, filterDTO: FilterDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerFindAllGivenFilter(instant, filterDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerFindAllGivenFilter']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerFindEntity(findByColumnQueryUUID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerFindEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerGetAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerGetAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerGetAllEntities(instant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerGetAllEntities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerGetById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerGetById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerRemoveEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerRemoveEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {StepRevisionIdEssential} stepRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerUpdateEntity(stepRevisionIdEssential: StepRevisionIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerUpdateEntity(stepRevisionIdEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerUpdateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {StepRevision} stepRevision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerUpdateEntityForce(stepRevision: StepRevision, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerUpdateEntityForce(stepRevision, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.stepRevisionControllerUpdateEntityForce']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} filterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerAssignFilter(userId: string, filterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserFilter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerAssignFilter(userId, filterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.userControllerAssignFilter']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserEssential} userEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerCreateEntity(userEssential: UserEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerCreateEntity(userEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.userControllerCreateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerDeleteEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.userControllerDeleteEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.userControllerGetAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.userControllerGetById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetFiltersByUser(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Filter>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetFiltersByUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.userControllerGetFiltersByUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} filterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUnassignFilter(userId: string, filterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUnassignFilter(userId, filterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.userControllerUnassignFilter']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneControllerCreateEntity(body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Zone>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneControllerCreateEntity(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.zoneControllerCreateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneControllerDeleteEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.zoneControllerDeleteEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Zone>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneControllerGetAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.zoneControllerGetAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneControllerGetAllPermissionsByUserId(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZoneAggregate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneControllerGetAllPermissionsByUserId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.zoneControllerGetAllPermissionsByUserId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Zone>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneControllerGetById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.zoneControllerGetById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ZoneIdEssential} zoneIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneControllerUpdateEntity(zoneIdEssential: ZoneIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Zone>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneControllerUpdateEntity(zoneIdEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.zoneControllerUpdateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ZoneLevelEssential} zoneLevelEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneLevelControllerCreateEntity(zoneLevelEssential: ZoneLevelEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneLevel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneLevelControllerCreateEntity(zoneLevelEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.zoneLevelControllerCreateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneLevelControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneLevelControllerDeleteEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.zoneLevelControllerDeleteEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneLevelControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZoneLevel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneLevelControllerGetAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.zoneLevelControllerGetAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneLevelControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneLevel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneLevelControllerGetById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.zoneLevelControllerGetById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ZoneLevelIdEssential} zoneLevelIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneLevelControllerUpdateEntity(zoneLevelIdEssential: ZoneLevelIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneLevel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneLevelControllerUpdateEntity(zoneLevelIdEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.zoneLevelControllerUpdateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ZoneOwnerEssential} zoneOwnerEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneOwnerControllerCreateEntity(zoneOwnerEssential: ZoneOwnerEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneOwner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneOwnerControllerCreateEntity(zoneOwnerEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.zoneOwnerControllerCreateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneOwnerControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneOwnerControllerDeleteEntity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.zoneOwnerControllerDeleteEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneOwnerControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZoneOwner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneOwnerControllerGetAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.zoneOwnerControllerGetAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneOwnerControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneOwner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneOwnerControllerGetById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.zoneOwnerControllerGetById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ZoneOwnerIdEssential} zoneOwnerIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneOwnerControllerUpdateEntity(zoneOwnerIdEssential: ZoneOwnerIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneOwner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneOwnerControllerUpdateEntity(zoneOwnerIdEssential, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.zoneOwnerControllerUpdateEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {AggregateControlEssential} aggregateControlEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlControllerCreateAggregate(aggregateControlEssential: AggregateControlEssential, options?: any): AxiosPromise<AggregateControlAll> {
            return localVarFp.aggregateControlControllerCreateAggregate(aggregateControlEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlControllerGetAggregate(id: string, instant: string, options?: any): AxiosPromise<AggregateControlAll> {
            return localVarFp.aggregateControlControllerGetAggregate(id, instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlControllerGetAllAggregate(instant: string, options?: any): AxiosPromise<Array<AggregateControlAll>> {
            return localVarFp.aggregateControlControllerGetAllAggregate(instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} step 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlControllerGetAllAggregateByStep(step: string, instant: string, options?: any): AxiosPromise<Array<AggregateControlAll>> {
            return localVarFp.aggregateControlControllerGetAllAggregateByStep(step, instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlControllerRemoveAggregate(id: string, options?: any): AxiosPromise<AggregateControlAll> {
            return localVarFp.aggregateControlControllerRemoveAggregate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AggregateControlAll} aggregateControlAll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlControllerUpdateAggregate(aggregateControlAll: AggregateControlAll, options?: any): AxiosPromise<AggregateControlAll> {
            return localVarFp.aggregateControlControllerUpdateAggregate(aggregateControlAll, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AggregateControlReviewEssential} aggregateControlReviewEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlReviewControllerCreateAggregate(aggregateControlReviewEssential: AggregateControlReviewEssential, options?: any): AxiosPromise<AggregateControlReviewAll> {
            return localVarFp.aggregateControlReviewControllerCreateAggregate(aggregateControlReviewEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlReviewControllerGetAggregate(id: string, instant: string, options?: any): AxiosPromise<AggregateControlReviewAll> {
            return localVarFp.aggregateControlReviewControllerGetAggregate(id, instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlReviewControllerGetAllAggregate(instant: string, options?: any): AxiosPromise<Array<AggregateControlReviewAll>> {
            return localVarFp.aggregateControlReviewControllerGetAllAggregate(instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlReviewControllerRemoveAggregate(id: string, options?: any): AxiosPromise<AggregateControlReviewAll> {
            return localVarFp.aggregateControlReviewControllerRemoveAggregate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AggregateControlReviewAll} aggregateControlReviewAll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlReviewControllerUpdateAggregate(aggregateControlReviewAll: AggregateControlReviewAll, options?: any): AxiosPromise<AggregateControlReviewAll> {
            return localVarFp.aggregateControlReviewControllerUpdateAggregate(aggregateControlReviewAll, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationControllerGetPossibleDelegates(email: string, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.authenticationControllerGetPossibleDelegates(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} processUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationControllerGetResponsibleUsers(processUuid: string, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.authenticationControllerGetResponsibleUsers(processUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlControllerCreateEntity(body: object, options?: any): AxiosPromise<Control> {
            return localVarFp.controlControllerCreateEntity(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlControllerDeleteEntity(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.controlControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlControllerGetAll(options?: any): AxiosPromise<Array<Control>> {
            return localVarFp.controlControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlControllerGetById(id: string, options?: any): AxiosPromise<Control> {
            return localVarFp.controlControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlIdEssential} controlIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlControllerUpdateEntity(controlIdEssential: ControlIdEssential, options?: any): AxiosPromise<Control> {
            return localVarFp.controlControllerUpdateEntity(controlIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlReviewEssential} controlReviewEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewControllerCreateEntity(controlReviewEssential: ControlReviewEssential, options?: any): AxiosPromise<ControlReview> {
            return localVarFp.controlReviewControllerCreateEntity(controlReviewEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewControllerDeleteEntity(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.controlReviewControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewControllerGetAll(options?: any): AxiosPromise<Array<ControlReview>> {
            return localVarFp.controlReviewControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewControllerGetById(id: string, options?: any): AxiosPromise<ControlReview> {
            return localVarFp.controlReviewControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlReviewIdEssential} controlReviewIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewControllerUpdateEntity(controlReviewIdEssential: ControlReviewIdEssential, options?: any): AxiosPromise<ControlReview> {
            return localVarFp.controlReviewControllerUpdateEntity(controlReviewIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlReviewDocumentEssential} controlReviewDocumentEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewDocumentControllerCreateEntity(controlReviewDocumentEssential: ControlReviewDocumentEssential, options?: any): AxiosPromise<ControlReviewDocument> {
            return localVarFp.controlReviewDocumentControllerCreateEntity(controlReviewDocumentEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewDocumentControllerDeleteEntity(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.controlReviewDocumentControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewDocumentControllerGetAll(options?: any): AxiosPromise<Array<ControlReviewDocument>> {
            return localVarFp.controlReviewDocumentControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewDocumentControllerGetById(id: string, options?: any): AxiosPromise<ControlReviewDocument> {
            return localVarFp.controlReviewDocumentControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlReviewDocumentIdEssential} controlReviewDocumentIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewDocumentControllerUpdateEntity(controlReviewDocumentIdEssential: ControlReviewDocumentIdEssential, options?: any): AxiosPromise<ControlReviewDocument> {
            return localVarFp.controlReviewDocumentControllerUpdateEntity(controlReviewDocumentIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlReviewRevisionEssential} controlReviewRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewRevisionControllerCreateEntity(controlReviewRevisionEssential: ControlReviewRevisionEssential, options?: any): AxiosPromise<ControlReviewRevision> {
            return localVarFp.controlReviewRevisionControllerCreateEntity(controlReviewRevisionEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewRevisionControllerDeleteEntity(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.controlReviewRevisionControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewRevisionControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: any): AxiosPromise<Array<ControlReviewRevision>> {
            return localVarFp.controlReviewRevisionControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewRevisionControllerGetAll(options?: any): AxiosPromise<Array<ControlReviewRevision>> {
            return localVarFp.controlReviewRevisionControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewRevisionControllerGetAllEntities(instant: string, options?: any): AxiosPromise<Array<ControlReviewRevision>> {
            return localVarFp.controlReviewRevisionControllerGetAllEntities(instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewRevisionControllerGetById(id: string, options?: any): AxiosPromise<ControlReviewRevision> {
            return localVarFp.controlReviewRevisionControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewRevisionControllerRemoveEntity(id: string, options?: any): AxiosPromise<ControlReviewRevision> {
            return localVarFp.controlReviewRevisionControllerRemoveEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlReviewRevisionIdEssential} controlReviewRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewRevisionControllerUpdateEntity(controlReviewRevisionIdEssential: ControlReviewRevisionIdEssential, options?: any): AxiosPromise<ControlReviewRevision> {
            return localVarFp.controlReviewRevisionControllerUpdateEntity(controlReviewRevisionIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlReviewStatusEssential} controlReviewStatusEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewStatusControllerCreateEntity(controlReviewStatusEssential: ControlReviewStatusEssential, options?: any): AxiosPromise<ControlReviewStatus> {
            return localVarFp.controlReviewStatusControllerCreateEntity(controlReviewStatusEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewStatusControllerDeleteEntity(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.controlReviewStatusControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewStatusControllerGetAll(options?: any): AxiosPromise<Array<ControlReviewStatus>> {
            return localVarFp.controlReviewStatusControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewStatusControllerGetById(id: string, options?: any): AxiosPromise<ControlReviewStatus> {
            return localVarFp.controlReviewStatusControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlReviewStatusIdEssential} controlReviewStatusIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewStatusControllerUpdateEntity(controlReviewStatusIdEssential: ControlReviewStatusIdEssential, options?: any): AxiosPromise<ControlReviewStatus> {
            return localVarFp.controlReviewStatusControllerUpdateEntity(controlReviewStatusIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlRevisionEssential} controlRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerCreateEntity(controlRevisionEssential: ControlRevisionEssential, options?: any): AxiosPromise<ControlRevision> {
            return localVarFp.controlRevisionControllerCreateEntity(controlRevisionEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerDeleteEntity(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.controlRevisionControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: any): AxiosPromise<Array<ControlRevision>> {
            return localVarFp.controlRevisionControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerGetAll(options?: any): AxiosPromise<Array<ControlRevision>> {
            return localVarFp.controlRevisionControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerGetAllEntities(instant: string, options?: any): AxiosPromise<Array<ControlRevision>> {
            return localVarFp.controlRevisionControllerGetAllEntities(instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerGetById(id: string, options?: any): AxiosPromise<ControlRevision> {
            return localVarFp.controlRevisionControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerRemoveEntity(id: string, options?: any): AxiosPromise<ControlRevision> {
            return localVarFp.controlRevisionControllerRemoveEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlRevisionIdEssential} controlRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerUpdateEntity(controlRevisionIdEssential: ControlRevisionIdEssential, options?: any): AxiosPromise<ControlRevision> {
            return localVarFp.controlRevisionControllerUpdateEntity(controlRevisionIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlStepEssential} controlStepEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerCreateEntity(controlStepEssential: ControlStepEssential, options?: any): AxiosPromise<ControlStep> {
            return localVarFp.controlStepControllerCreateEntity(controlStepEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerDeleteEntity(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.controlStepControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: any): AxiosPromise<Array<ControlStep>> {
            return localVarFp.controlStepControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerGetAll(options?: any): AxiosPromise<Array<ControlStep>> {
            return localVarFp.controlStepControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerGetAllEntities(instant: string, options?: any): AxiosPromise<Array<ControlStep>> {
            return localVarFp.controlStepControllerGetAllEntities(instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerGetById(id: string, options?: any): AxiosPromise<ControlStep> {
            return localVarFp.controlStepControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerRemoveEntity(id: string, options?: any): AxiosPromise<ControlStep> {
            return localVarFp.controlStepControllerRemoveEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlStepIdEssential} controlStepIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerUpdateEntity(controlStepIdEssential: ControlStepIdEssential, options?: any): AxiosPromise<ControlStep> {
            return localVarFp.controlStepControllerUpdateEntity(controlStepIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} zone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreTasksApiControllerImportAndSaveSteps(zone: string, options?: any): AxiosPromise<Array<Step>> {
            return localVarFp.coreTasksApiControllerImportAndSaveSteps(zone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreTasksApiControllerPing(options?: any): AxiosPromise<string> {
            return localVarFp.coreTasksApiControllerPing(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        displayDataControllerGetControls(instant: string, options?: any): AxiosPromise<string> {
            return localVarFp.displayDataControllerGetControls(instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} to 
         * @param {string} subject 
         * @param {EmailParams} emailParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailControllerSendEmail(to: Array<string>, subject: string, emailParams: EmailParams, options?: any): AxiosPromise<boolean> {
            return localVarFp.emailControllerSendEmail(to, subject, emailParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDataControllerGetAllEmployeeData(options?: any): AxiosPromise<Array<EmployeeData>> {
            return localVarFp.employeeDataControllerGetAllEmployeeData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FilterEssential} filterEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterControllerCreateEntity(filterEssential: FilterEssential, options?: any): AxiosPromise<Filter> {
            return localVarFp.filterControllerCreateEntity(filterEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterControllerDeleteEntity(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.filterControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterControllerGetAll(options?: any): AxiosPromise<Array<Filter>> {
            return localVarFp.filterControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterControllerGetById(id: string, options?: any): AxiosPromise<Filter> {
            return localVarFp.filterControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} filterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterControllerGetUserByFilter(filterId: string, options?: any): AxiosPromise<Array<User>> {
            return localVarFp.filterControllerGetUserByFilter(filterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FilterIdEssential} filterIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterControllerUpdateEntity(filterIdEssential: FilterIdEssential, options?: any): AxiosPromise<Filter> {
            return localVarFp.filterControllerUpdateEntity(filterIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {LayoutBodyDTO} layoutBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphControllerGetNestedLayout(instant: string, layoutBodyDTO: LayoutBodyDTO, options?: any): AxiosPromise<Array<GraphNode>> {
            return localVarFp.graphControllerGetNestedLayout(instant, layoutBodyDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {LayoutBodyDTO} layoutBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphControllerGetRenderedEdges(instant: string, layoutBodyDTO: LayoutBodyDTO, options?: any): AxiosPromise<Array<GraphEdge>> {
            return localVarFp.graphControllerGetRenderedEdges(instant, layoutBodyDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobAidControllerGetFromUrl(options?: any): AxiosPromise<Array<JobAid>> {
            return localVarFp.jobAidControllerGetFromUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerCreateEntity(body: object, options?: any): AxiosPromise<Step> {
            return localVarFp.stepControllerCreateEntity(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerDeleteEntity(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.stepControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerGetAll(options?: any): AxiosPromise<Array<Step>> {
            return localVarFp.stepControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerGetById(id: string, options?: any): AxiosPromise<Step> {
            return localVarFp.stepControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StepIdEssential} stepIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerUpdateEntity(stepIdEssential: StepIdEssential, options?: any): AxiosPromise<Step> {
            return localVarFp.stepControllerUpdateEntity(stepIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StepLinkEssential} stepLinkEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerCreateEntity(stepLinkEssential: StepLinkEssential, options?: any): AxiosPromise<StepLink> {
            return localVarFp.stepLinkControllerCreateEntity(stepLinkEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerDeleteEntity(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.stepLinkControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: any): AxiosPromise<Array<StepLink>> {
            return localVarFp.stepLinkControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerGetAll(options?: any): AxiosPromise<Array<StepLink>> {
            return localVarFp.stepLinkControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerGetAllEntities(instant: string, options?: any): AxiosPromise<Array<StepLink>> {
            return localVarFp.stepLinkControllerGetAllEntities(instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerGetById(id: string, options?: any): AxiosPromise<StepLink> {
            return localVarFp.stepLinkControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerRemoveEntity(id: string, options?: any): AxiosPromise<StepLink> {
            return localVarFp.stepLinkControllerRemoveEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StepLinkIdEssential} stepLinkIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerUpdateEntity(stepLinkIdEssential: StepLinkIdEssential, options?: any): AxiosPromise<StepLink> {
            return localVarFp.stepLinkControllerUpdateEntity(stepLinkIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<StepRevision>} stepRevision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerCreateBulkEntityForce(stepRevision: Array<StepRevision>, options?: any): AxiosPromise<Array<StepRevision>> {
            return localVarFp.stepRevisionControllerCreateBulkEntityForce(stepRevision, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StepRevisionEssential} stepRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerCreateEntity(stepRevisionEssential: StepRevisionEssential, options?: any): AxiosPromise<StepRevision> {
            return localVarFp.stepRevisionControllerCreateEntity(stepRevisionEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StepRevision} stepRevision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerCreateEntityForce(stepRevision: StepRevision, options?: any): AxiosPromise<StepRevision> {
            return localVarFp.stepRevisionControllerCreateEntityForce(stepRevision, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerDeleteEntity(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.stepRevisionControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fieldName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerFindAllDistinct(fieldName: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.stepRevisionControllerFindAllDistinct(fieldName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fieldName 
         * @param {FilterDTO} filterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerFindAllDistinctGivenFilter(fieldName: string, filterDTO: FilterDTO, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.stepRevisionControllerFindAllDistinctGivenFilter(fieldName, filterDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {FilterDTO} filterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerFindAllGivenFilter(instant: string, filterDTO: FilterDTO, options?: any): AxiosPromise<Array<StepRevision>> {
            return localVarFp.stepRevisionControllerFindAllGivenFilter(instant, filterDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: any): AxiosPromise<Array<StepRevision>> {
            return localVarFp.stepRevisionControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerGetAll(options?: any): AxiosPromise<Array<StepRevision>> {
            return localVarFp.stepRevisionControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerGetAllEntities(instant: string, options?: any): AxiosPromise<Array<StepRevision>> {
            return localVarFp.stepRevisionControllerGetAllEntities(instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerGetById(id: string, options?: any): AxiosPromise<StepRevision> {
            return localVarFp.stepRevisionControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerRemoveEntity(id: string, options?: any): AxiosPromise<StepRevision> {
            return localVarFp.stepRevisionControllerRemoveEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StepRevisionIdEssential} stepRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerUpdateEntity(stepRevisionIdEssential: StepRevisionIdEssential, options?: any): AxiosPromise<StepRevision> {
            return localVarFp.stepRevisionControllerUpdateEntity(stepRevisionIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StepRevision} stepRevision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerUpdateEntityForce(stepRevision: StepRevision, options?: any): AxiosPromise<StepRevision> {
            return localVarFp.stepRevisionControllerUpdateEntityForce(stepRevision, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} filterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerAssignFilter(userId: string, filterId: string, options?: any): AxiosPromise<UserFilter> {
            return localVarFp.userControllerAssignFilter(userId, filterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserEssential} userEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreateEntity(userEssential: UserEssential, options?: any): AxiosPromise<User> {
            return localVarFp.userControllerCreateEntity(userEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerDeleteEntity(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.userControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetAll(options?: any): AxiosPromise<Array<User>> {
            return localVarFp.userControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetById(id: string, options?: any): AxiosPromise<User> {
            return localVarFp.userControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetFiltersByUser(userId: string, options?: any): AxiosPromise<Array<Filter>> {
            return localVarFp.userControllerGetFiltersByUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} filterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUnassignFilter(userId: string, filterId: string, options?: any): AxiosPromise<string> {
            return localVarFp.userControllerUnassignFilter(userId, filterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerCreateEntity(body: object, options?: any): AxiosPromise<Zone> {
            return localVarFp.zoneControllerCreateEntity(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerDeleteEntity(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.zoneControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerGetAll(options?: any): AxiosPromise<Array<Zone>> {
            return localVarFp.zoneControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerGetAllPermissionsByUserId(id: string, options?: any): AxiosPromise<Array<ZoneAggregate>> {
            return localVarFp.zoneControllerGetAllPermissionsByUserId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerGetById(id: string, options?: any): AxiosPromise<Zone> {
            return localVarFp.zoneControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ZoneIdEssential} zoneIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerUpdateEntity(zoneIdEssential: ZoneIdEssential, options?: any): AxiosPromise<Zone> {
            return localVarFp.zoneControllerUpdateEntity(zoneIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ZoneLevelEssential} zoneLevelEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneLevelControllerCreateEntity(zoneLevelEssential: ZoneLevelEssential, options?: any): AxiosPromise<ZoneLevel> {
            return localVarFp.zoneLevelControllerCreateEntity(zoneLevelEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneLevelControllerDeleteEntity(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.zoneLevelControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneLevelControllerGetAll(options?: any): AxiosPromise<Array<ZoneLevel>> {
            return localVarFp.zoneLevelControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneLevelControllerGetById(id: string, options?: any): AxiosPromise<ZoneLevel> {
            return localVarFp.zoneLevelControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ZoneLevelIdEssential} zoneLevelIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneLevelControllerUpdateEntity(zoneLevelIdEssential: ZoneLevelIdEssential, options?: any): AxiosPromise<ZoneLevel> {
            return localVarFp.zoneLevelControllerUpdateEntity(zoneLevelIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ZoneOwnerEssential} zoneOwnerEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneOwnerControllerCreateEntity(zoneOwnerEssential: ZoneOwnerEssential, options?: any): AxiosPromise<ZoneOwner> {
            return localVarFp.zoneOwnerControllerCreateEntity(zoneOwnerEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneOwnerControllerDeleteEntity(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.zoneOwnerControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneOwnerControllerGetAll(options?: any): AxiosPromise<Array<ZoneOwner>> {
            return localVarFp.zoneOwnerControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneOwnerControllerGetById(id: string, options?: any): AxiosPromise<ZoneOwner> {
            return localVarFp.zoneOwnerControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ZoneOwnerIdEssential} zoneOwnerIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneOwnerControllerUpdateEntity(zoneOwnerIdEssential: ZoneOwnerIdEssential, options?: any): AxiosPromise<ZoneOwner> {
            return localVarFp.zoneOwnerControllerUpdateEntity(zoneOwnerIdEssential, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {AggregateControlEssential} aggregateControlEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateControlControllerCreateAggregate(aggregateControlEssential: AggregateControlEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateControlControllerCreateAggregate(aggregateControlEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateControlControllerGetAggregate(id: string, instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateControlControllerGetAggregate(id, instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateControlControllerGetAllAggregate(instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateControlControllerGetAllAggregate(instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} step 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateControlControllerGetAllAggregateByStep(step: string, instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateControlControllerGetAllAggregateByStep(step, instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateControlControllerRemoveAggregate(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateControlControllerRemoveAggregate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AggregateControlAll} aggregateControlAll 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateControlControllerUpdateAggregate(aggregateControlAll: AggregateControlAll, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateControlControllerUpdateAggregate(aggregateControlAll, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AggregateControlReviewEssential} aggregateControlReviewEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateControlReviewControllerCreateAggregate(aggregateControlReviewEssential: AggregateControlReviewEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateControlReviewControllerCreateAggregate(aggregateControlReviewEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateControlReviewControllerGetAggregate(id: string, instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateControlReviewControllerGetAggregate(id, instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateControlReviewControllerGetAllAggregate(instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateControlReviewControllerGetAllAggregate(instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateControlReviewControllerRemoveAggregate(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateControlReviewControllerRemoveAggregate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AggregateControlReviewAll} aggregateControlReviewAll 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateControlReviewControllerUpdateAggregate(aggregateControlReviewAll: AggregateControlReviewAll, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateControlReviewControllerUpdateAggregate(aggregateControlReviewAll, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authenticationControllerGetPossibleDelegates(email: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authenticationControllerGetPossibleDelegates(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} processUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authenticationControllerGetResponsibleUsers(processUuid: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authenticationControllerGetResponsibleUsers(processUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlControllerCreateEntity(body: object, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlControllerCreateEntity(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlIdEssential} controlIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlControllerUpdateEntity(controlIdEssential: ControlIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlControllerUpdateEntity(controlIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlReviewEssential} controlReviewEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewControllerCreateEntity(controlReviewEssential: ControlReviewEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewControllerCreateEntity(controlReviewEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlReviewIdEssential} controlReviewIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewControllerUpdateEntity(controlReviewIdEssential: ControlReviewIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewControllerUpdateEntity(controlReviewIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlReviewDocumentEssential} controlReviewDocumentEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewDocumentControllerCreateEntity(controlReviewDocumentEssential: ControlReviewDocumentEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewDocumentControllerCreateEntity(controlReviewDocumentEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewDocumentControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewDocumentControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewDocumentControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewDocumentControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewDocumentControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewDocumentControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlReviewDocumentIdEssential} controlReviewDocumentIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewDocumentControllerUpdateEntity(controlReviewDocumentIdEssential: ControlReviewDocumentIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewDocumentControllerUpdateEntity(controlReviewDocumentIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlReviewRevisionEssential} controlReviewRevisionEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewRevisionControllerCreateEntity(controlReviewRevisionEssential: ControlReviewRevisionEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewRevisionControllerCreateEntity(controlReviewRevisionEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewRevisionControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewRevisionControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewRevisionControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewRevisionControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewRevisionControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewRevisionControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewRevisionControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewRevisionControllerGetAllEntities(instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewRevisionControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewRevisionControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewRevisionControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewRevisionControllerRemoveEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlReviewRevisionIdEssential} controlReviewRevisionIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewRevisionControllerUpdateEntity(controlReviewRevisionIdEssential: ControlReviewRevisionIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewRevisionControllerUpdateEntity(controlReviewRevisionIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlReviewStatusEssential} controlReviewStatusEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewStatusControllerCreateEntity(controlReviewStatusEssential: ControlReviewStatusEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewStatusControllerCreateEntity(controlReviewStatusEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewStatusControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewStatusControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewStatusControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewStatusControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewStatusControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewStatusControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlReviewStatusIdEssential} controlReviewStatusIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewStatusControllerUpdateEntity(controlReviewStatusIdEssential: ControlReviewStatusIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewStatusControllerUpdateEntity(controlReviewStatusIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlRevisionEssential} controlRevisionEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlRevisionControllerCreateEntity(controlRevisionEssential: ControlRevisionEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlRevisionControllerCreateEntity(controlRevisionEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlRevisionControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlRevisionControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlRevisionControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlRevisionControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlRevisionControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlRevisionControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlRevisionControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlRevisionControllerGetAllEntities(instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlRevisionControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlRevisionControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlRevisionControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlRevisionControllerRemoveEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlRevisionIdEssential} controlRevisionIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlRevisionControllerUpdateEntity(controlRevisionIdEssential: ControlRevisionIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlRevisionControllerUpdateEntity(controlRevisionIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlStepEssential} controlStepEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlStepControllerCreateEntity(controlStepEssential: ControlStepEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlStepControllerCreateEntity(controlStepEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlStepControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlStepControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlStepControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlStepControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlStepControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlStepControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlStepControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlStepControllerGetAllEntities(instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlStepControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlStepControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlStepControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlStepControllerRemoveEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlStepIdEssential} controlStepIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlStepControllerUpdateEntity(controlStepIdEssential: ControlStepIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlStepControllerUpdateEntity(controlStepIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} zone 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public coreTasksApiControllerImportAndSaveSteps(zone: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).coreTasksApiControllerImportAndSaveSteps(zone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public coreTasksApiControllerPing(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).coreTasksApiControllerPing(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public displayDataControllerGetControls(instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).displayDataControllerGetControls(instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} to 
     * @param {string} subject 
     * @param {EmailParams} emailParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public emailControllerSendEmail(to: Array<string>, subject: string, emailParams: EmailParams, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).emailControllerSendEmail(to, subject, emailParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public employeeDataControllerGetAllEmployeeData(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).employeeDataControllerGetAllEmployeeData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FilterEssential} filterEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public filterControllerCreateEntity(filterEssential: FilterEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).filterControllerCreateEntity(filterEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public filterControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).filterControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public filterControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).filterControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public filterControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).filterControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} filterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public filterControllerGetUserByFilter(filterId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).filterControllerGetUserByFilter(filterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FilterIdEssential} filterIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public filterControllerUpdateEntity(filterIdEssential: FilterIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).filterControllerUpdateEntity(filterIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {LayoutBodyDTO} layoutBodyDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public graphControllerGetNestedLayout(instant: string, layoutBodyDTO: LayoutBodyDTO, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).graphControllerGetNestedLayout(instant, layoutBodyDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {LayoutBodyDTO} layoutBodyDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public graphControllerGetRenderedEdges(instant: string, layoutBodyDTO: LayoutBodyDTO, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).graphControllerGetRenderedEdges(instant, layoutBodyDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public jobAidControllerGetFromUrl(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).jobAidControllerGetFromUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepControllerCreateEntity(body: object, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepControllerCreateEntity(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StepIdEssential} stepIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepControllerUpdateEntity(stepIdEssential: StepIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepControllerUpdateEntity(stepIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StepLinkEssential} stepLinkEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepLinkControllerCreateEntity(stepLinkEssential: StepLinkEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepLinkControllerCreateEntity(stepLinkEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepLinkControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepLinkControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepLinkControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepLinkControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepLinkControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepLinkControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepLinkControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepLinkControllerGetAllEntities(instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepLinkControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepLinkControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepLinkControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepLinkControllerRemoveEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StepLinkIdEssential} stepLinkIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepLinkControllerUpdateEntity(stepLinkIdEssential: StepLinkIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepLinkControllerUpdateEntity(stepLinkIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<StepRevision>} stepRevision 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerCreateBulkEntityForce(stepRevision: Array<StepRevision>, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerCreateBulkEntityForce(stepRevision, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StepRevisionEssential} stepRevisionEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerCreateEntity(stepRevisionEssential: StepRevisionEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerCreateEntity(stepRevisionEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StepRevision} stepRevision 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerCreateEntityForce(stepRevision: StepRevision, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerCreateEntityForce(stepRevision, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} fieldName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerFindAllDistinct(fieldName: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerFindAllDistinct(fieldName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} fieldName 
     * @param {FilterDTO} filterDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerFindAllDistinctGivenFilter(fieldName: string, filterDTO: FilterDTO, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerFindAllDistinctGivenFilter(fieldName, filterDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {FilterDTO} filterDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerFindAllGivenFilter(instant: string, filterDTO: FilterDTO, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerFindAllGivenFilter(instant, filterDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerGetAllEntities(instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerRemoveEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StepRevisionIdEssential} stepRevisionIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerUpdateEntity(stepRevisionIdEssential: StepRevisionIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerUpdateEntity(stepRevisionIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StepRevision} stepRevision 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerUpdateEntityForce(stepRevision: StepRevision, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerUpdateEntityForce(stepRevision, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {string} filterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userControllerAssignFilter(userId: string, filterId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userControllerAssignFilter(userId, filterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserEssential} userEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userControllerCreateEntity(userEssential: UserEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userControllerCreateEntity(userEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userControllerGetFiltersByUser(userId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userControllerGetFiltersByUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {string} filterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userControllerUnassignFilter(userId: string, filterId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userControllerUnassignFilter(userId, filterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zoneControllerCreateEntity(body: object, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zoneControllerCreateEntity(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zoneControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zoneControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zoneControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zoneControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zoneControllerGetAllPermissionsByUserId(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zoneControllerGetAllPermissionsByUserId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zoneControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zoneControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ZoneIdEssential} zoneIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zoneControllerUpdateEntity(zoneIdEssential: ZoneIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zoneControllerUpdateEntity(zoneIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ZoneLevelEssential} zoneLevelEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zoneLevelControllerCreateEntity(zoneLevelEssential: ZoneLevelEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zoneLevelControllerCreateEntity(zoneLevelEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zoneLevelControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zoneLevelControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zoneLevelControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zoneLevelControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zoneLevelControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zoneLevelControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ZoneLevelIdEssential} zoneLevelIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zoneLevelControllerUpdateEntity(zoneLevelIdEssential: ZoneLevelIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zoneLevelControllerUpdateEntity(zoneLevelIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ZoneOwnerEssential} zoneOwnerEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zoneOwnerControllerCreateEntity(zoneOwnerEssential: ZoneOwnerEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zoneOwnerControllerCreateEntity(zoneOwnerEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zoneOwnerControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zoneOwnerControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zoneOwnerControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zoneOwnerControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zoneOwnerControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zoneOwnerControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ZoneOwnerIdEssential} zoneOwnerIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zoneOwnerControllerUpdateEntity(zoneOwnerIdEssential: ZoneOwnerIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zoneOwnerControllerUpdateEntity(zoneOwnerIdEssential, options).then((request) => request(this.axios, this.basePath));
    }
}

